import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { LoadingWrapper } from 'styles/style';
import { useDispatch } from 'react-redux';
import commonActions from 'redux/common/actions';
import { PRELOAD_IMAGES } from 'config/settings';

const HomePage = lazy(() => import('pages/HomePage'));
const WorkshopPage = lazy(() => import('pages/WorkshopPage'));
const WorkshopDetailPage = lazy(() => import('pages/WorkshopDetailPage'));
const WorkshopImagesPage = lazy(() => import('pages/WorkshopImagesPage'));
const WorkshopAppointmentPage = lazy(() => import('pages/WorkshopAppointmentPage'));

const NotFound = () => {
    return <Redirect to="/" />;
};

const Routes = (): JSX.Element => {
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    const setTotalAssetsCount = (total: number) => dispatch(commonActions.setTotalAssetsCount(total));
    const addLoadedAssetsCount = () => dispatch(commonActions.addLoadedAssetsCount());

    // Preload images
    useEffect(() => {
        setTotalAssetsCount(PRELOAD_IMAGES.length);

        const images: any[] = [];

        PRELOAD_IMAGES.forEach((image, index) => {
            images[index] = new Image();
            images[index].src = image;
            addLoadedAssetsCount();
        });
    }, []);

    // Scroll to top when change path
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Suspense fallback={<LoadingWrapper>{/* <LoadingBar /> */}</LoadingWrapper>}>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/workshop" component={WorkshopPage} />
                <Route exact path="/workshop/:id" component={WorkshopDetailPage} />
                <Route exact path="/workshop/:id/images" component={WorkshopImagesPage} />
                <Route exact path="/workshop/:id/appointment" component={WorkshopAppointmentPage} />

                {/* Route Not Found */}
                <Route exact path="*" component={NotFound} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
