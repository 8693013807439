import React, { useState, useEffect } from 'react';
import { LoadingOverlayContainer, LoadingProgressBar } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducers';
import LoadingLogo from 'assets/img/loading.png';
import commonActions from 'redux/common/actions';

const LoadingOverlay = (): JSX.Element => {
    const [isCompleted, setIsCompleted] = useState(false);
    const [doneMinLoadingTime, setDoneMinLoadingTime] = useState(false);
    const [progress, setProgress] = useState(0);

    const dispatch = useDispatch();
    const resetLoadedAssetsCount = () => dispatch(commonActions.resetLoadedAssetsCount());

    const totalAssetsCount = useSelector(({ common }: RootState) => common.totalAssetsCount);
    const loadedAssetsCount = useSelector(({ common }: RootState) => common.loadedAssetsCount);

    // Handle the loading overlay based on assets loaded
    useEffect(() => {
        if (isCompleted) return;
        
        setProgress(loadedAssetsCount / totalAssetsCount * 100);

        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';

        if (loadedAssetsCount >= totalAssetsCount && doneMinLoadingTime) {
            resetLoadedAssetsCount();
            setIsCompleted(true);
            document.body.style.overflowY = 'auto';
            document.body.style.height = 'auto';
            return;
        }
    }, [totalAssetsCount, loadedAssetsCount, doneMinLoadingTime]);

    // Set loading minimum time
    useEffect(() => {
        setTimeout(() => {
            setDoneMinLoadingTime(true);
        }, 2000);
    }, [totalAssetsCount])

    // Once completed, destroy the loading overlay
    if (isCompleted) {
        return <></>;
    }

    return (
        <LoadingOverlayContainer>
            <img className="app-logo px-0" src={LoadingLogo} alt="LiquiMoly" />

            <LoadingProgressBar>
                <div style={{ width: `${progress}%` }} className="progress" />
            </LoadingProgressBar>
        </LoadingOverlayContainer>
    );
};

export default LoadingOverlay;
