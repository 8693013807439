import LoadingLogo from 'assets/img/loading.png';

import ArrowDown from 'assets/img/home/arrow_down.svg';
import GridViewIconNormal from 'assets/img/home/grid_view_icon.svg';
import GridViewIconRed from 'assets/img/home/grid_view_icon_red.svg';
import ListViewIconNormal from 'assets/img/home/list_view_icon.svg';
import ListViewIconRed from 'assets/img/home/list_view_icon_red.svg';
import Workshop1 from 'assets/img/home/workshop_1.png';
import Workshop2 from 'assets/img/home/workshop_2.png';
import Workshop3 from 'assets/img/home/workshop_3.png';
import Checkbox from 'assets/img/home/checkbox.svg';
import CheckboxChecked from 'assets/img/home/checkbox_checked.svg';

import chevronLeft from 'assets/img/chevron-left.svg';

import mail from 'assets/img/detailSection/mail.svg';
import contact from 'assets/img/detailSection/contact.svg';
import direction from 'assets/img/detailSection/direction.svg';
import paginationLeft from 'assets/img/detailSection/pagination_left.svg';
import product1 from 'assets/img/detailSection/product_1.svg';
import imageAlbum from 'assets/img/image_album.svg';
import diagnostic from 'assets/img/detailSection/diagnostic.svg';
import maintenance from 'assets/img/detailSection/maintenance.svg';
import servicing from 'assets/img/detailSection/servicing.svg';
import battery_replacement from 'assets/img/detailSection/battery_replacement.svg';
import tyre_change from 'assets/img/detailSection/tyre_change.svg';
import car_wash from 'assets/img/detailSection/car_wash.svg';
import repair from 'assets/img/detailSection/repair.svg';
import accessories from 'assets/img/detailSection/accessories.svg';
import clockOpen from 'assets/img/detailSection/clock_open.svg';
import clockClose from 'assets/img/detailSection/clock_close.svg';
import chevron from 'assets/img/detailSection/chevron_up.svg';
import detail_example1 from 'assets/img/imageSection/example1.png';
import detail_example3 from 'assets/img/imageSection/example3.png';
import detail_example5 from 'assets/img/imageSection/example5.png';
import detail_example6 from 'assets/img/imageSection/example6.png';

import image_example1 from 'assets/img/imageSection/example1.png';
import image_example7 from 'assets/img/imageSection/example7.png';
import image_example3 from 'assets/img/imageSection/example3.png';
import image_example4 from 'assets/img/imageSection/example4.png';
import image_example5 from 'assets/img/imageSection/example5.png';
import image_example6 from 'assets/img/imageSection/example6.png';

import workshop_example1 from 'assets/img/example1.png';
import workshop_example5 from 'assets/img/example5.png';
import workshop_example6 from 'assets/img/example6.png';

import locate from 'assets/img/locate.svg';
import phone from 'assets/img/phone.svg';
import clock from 'assets/img/clock.svg';

interface SETTINGS_TYPE {
    defaultMapCenter: {
        lat: number;
        lng: number;
    };
}

const SETTINGS: SETTINGS_TYPE = {
    defaultMapCenter: {
        lat: 4.2105,
        lng: 101.9758,
    },
};

const PRELOAD_IMAGES: string[] = [
    // Loading screen
    LoadingLogo,

    // Home page
    ArrowDown,
    GridViewIconNormal,
    GridViewIconRed,
    ListViewIconNormal,
    ListViewIconRed,
    Workshop1,
    Workshop2,
    Workshop3,
    Checkbox,
    CheckboxChecked,

    // Appointment Page
    chevronLeft,

    // Detail page
    mail,
    contact,
    direction,
    paginationLeft,
    product1,
    imageAlbum,
    diagnostic,
    maintenance,
    servicing,
    battery_replacement,
    tyre_change,
    car_wash,
    repair,
    accessories,
    clockOpen,
    clockClose,
    chevron,
    detail_example1,
    detail_example3,
    detail_example5,
    detail_example6,

    // Images page
    image_example1,
    image_example7,
    image_example3,
    image_example4,
    image_example5,
    image_example6,

    // Workshop page
    workshop_example1,
    workshop_example5,
    workshop_example6,

    // Workshop card
    locate,
    phone,
    clock,
]

export { SETTINGS, PRELOAD_IMAGES };
