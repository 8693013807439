import Styled from 'styled-components';

const LoadingOverlayContainer = Styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #00519E;
    background-size: cover;
    z-index: 9999;
    overflow: hidden;

    .app-logo {
        width: 230px;
        height: auto;
        margin: 0 auto;
    }
`;

const LoadingProgressBar = Styled.div`
    position: relative;
    width: 180px;
    height: 18px;
    background: #C2C2C2;
    border: 2.62045px solid #FFFFFF;
    border-radius: 6.55113px;
    display: flex;
    align-items: center;

    .progress {
        height: 18px;
        background-color: #E30613;
        transition: 1s ease;
        transition-delay: 0.5s;
        border: 2.62045px solid #FFFFFF;
        border-radius: 6.55113px;
    }
`;

export { LoadingOverlayContainer, LoadingProgressBar };
