import Styled from 'styled-components';

const LoadingWrapper = Styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
`;

const PageWrapper = Styled.div`
    position: relative;
    min-height: 100vh;
`;

const ContentBodyWrapper = Styled.div`
    position: relative;
`;

export { LoadingWrapper, PageWrapper, ContentBodyWrapper };
