import commonActions from './actions';
interface Action {
    type: string;
    payload: any;
}

const initialState = {
    totalAssetsCount: 9999,
    loadedAssetsCount: 0,
};

const CommonReducer = (state = initialState, action: Action): any => {
    switch (action.type) {
        case commonActions.SET_TOTAL_ASSETS_COUNT:
            return {
                ...state,
                totalAssetsCount: action.payload.count,
            };
        case commonActions.ADD_LOADED_ASSETS_COUNT:
            return {
                ...state,
                loadedAssetsCount: state.loadedAssetsCount + 1,
            };
        case commonActions.RESET_LOADED_ASSETS_COUNT:
            return {
                ...state,
                totalAssetsCount: 9999,
                loadedAssetsCount: 0,
            };

        default:
            return state;
    }
};

export default CommonReducer;
